<template>
    <div class="row mt-60">
      <loader-component v-if="loading"/>
      <div class="col-md-4">
        <div class="c-card">
          <div class="filter-section">
            
            <form>
              <ul class="filter-list">
              <li>
                <b-button @click="selectFilter('Month')" class="coll-btn"
                >{{ filter.quarter?'Q'+filter.quarter:filter.month?filter.month :'Month' }} {{ getYearLabel(filter.year) }} </b-button
                >

                <!-- Element to collapse -->
              </li>
            
              <li>
                <b-button  class="coll-btn"  @click="setSalesOrCollection(filter.report_type)"  :class="filter.report_type == 'collection' ? 'collection-active-bg' :''"
                >{{ capitalizeFirstLetter(filter.report_type) || 'Sales' }}
                </b-button
                >
              </li>
              <li>
                <b-button @click="pcsOrTk(filter.type)" class="coll-btn" :class="filter.type == 'tk' ? 'tk-active-bg' :''"
                >{{ capitalizeFirstLetter(filter.type) || 'Pcs' }} </b-button
                >
              </li>
            </ul>

            <b-collapse id="collapse-1" v-model="showMonthFilter">
              <ul class="month-selection-list">
                <li v-for="(item, key) in months" :key="key">
                  <a v-if="!['Q1', 'Q2', 'Q3', 'Q4'].includes(item.text)" href="javascript:void(0)" :class="item.text == filter.month?'active':''" @click="selectPeriod('monthly',item.text); showMonthFilter=false; getReports()">{{ item.text }}  </a>
                  <a v-else href="javascript:void(0)" :class="item.value == filter.quarter?'active':''" @click="selectPeriod('quarterly',item.value); showMonthFilter=false; getReports()">{{ item.text }} </a>
                </li>
                
              </ul>
              <ul class="year-selection-list">
                <li>
                  <a href="javascript:void(0)" :class="filter.year == year ?'active':''" @click="filter.year = year; getReports()" v-for="(year, index) in years"  :key="index">{{  year }}</a>
                </li>
              </ul>
            </b-collapse>

            <b-collapse id="collapse-4" v-model="showTypeFilter">
              <ul class="area-selection-list">
                <li>

                  <a v-if="filter.report_type == 'sales'" href="javascript:void(0)" :class="filter.type == 'pcs'?'active':''" @click="filter.type = 'pcs'; showTypeFilter=false; getReports()">Pcs</a>

                </li>
                <li>
                  <a href="javascript:void(0)" :class="filter.type == 'tk'?'active':''" @click="filter.type = 'tk'; showTypeFilter=false; getReports()">Tk</a>
                </li>
              </ul>
            </b-collapse>
            <b-collapse id="collapse-4" v-model="showSalesFilter">
              <ul class="report-selection-list">
                <li>

                  <a href="javascript:void(0)" :class="filter.report_type == 'sales'?'active':''" @click="filter.report_type = 'sales'; showSalesFilter=false; getReports()">Sales</a>

                </li>
                <li>
                  <a href="javascript:void(0)" :class="filter.report_type == 'collection'?'active':''" @click="filter.report_type = 'collection'; filter.type = 'tk';showSalesFilter=false; getReports()">Collection</a>
                </li>
              </ul>
            </b-collapse>
            </form>
         </div>
          <div class="target-content">
            
            <h2 class="content-title" v-if="this.filter.report_type == 'sales'">Product wise sales</h2>
            <ul v-if="this.filter.report_type == 'sales'">
              <li v-for="(product, index) in product_wise_sale" :key="index">
                <div>
                  <div class="l-label">
                    <label>{{ product.category_name }}</label>
                  </div>
                  <div class="r-label">
                    <span class="achieved">{{ numberFormat(product.total_sale)}}</span> /
                    <span class="target">{{  getTarget(product) }}</span>
                  </div>
                  <b-progress
                      :max="100"
                      height="25px"
                      variant="warning"
                      :precision="0"
                      show-progress
                      class="mb-3 brd-15 ftb-15 w-100"
                  >
                    <b-progress-bar :value="calculateProgress(product.total_sale, getTarget(product))">
                      <span>
                        <strong>{{ calculateProgress(product.total_sale, getTarget(product)) }} %</strong></span
                      >
                    </b-progress-bar>
                  </b-progress>
                </div>
              </li>
            </ul>

            <h2 class="content-title">Market wise {{ this.filter.report_type == 'sales'?'sales':'collection' }}</h2>
            <ul>
                <li v-for="(market,index) in market_wise_sale" :key="index">
                <div>
                  <div class="l-label">
                    <label>{{  market.sales_market }}</label>
                  </div>
                  <div class="r-label">
                    <span class="achieved">{{ numberFormat(market.total_sale) }}</span> /
                    <span class="target">{{  getTargetMarket(market) }}</span>
                  </div>
                  <b-progress
                      :max="100"
                      height="25px"
                      variant="warning"
                      :precision="0"
                      show-progress
                      class="mb-3 brd-15 ftb-15 w-100"
                  >
                    <b-progress-bar :value="calculateProgress(numberFormat(market.total_sale), getTargetMarket(market))">
                      <span>
                        <strong>{{ calculateProgress(numberFormat(market.total_sale), getTargetMarket(market)) }}%</strong></span
                      >
                    </b-progress-bar>
                  </b-progress>
                </div>
                </li>
            </ul>

           
            <ul>
                <li class="total">
                <div>
                  <div class="l-label">
                    <label>Total</label>
                  </div>
                  <div class="r-label">
                    <span class="achieved">{{ numberFormat(totalCount()) }}</span> /
                    <span class="target">{{ totalMarketSum() }}</span>
                  </div>
                  <b-progress
                      :max="100"
                      height="25px"
                      variant="warning"
                      :precision="0"
                      show-progress
                      class="mb-3 brd-15 ftb-15 w-100"
                  >
                    <b-progress-bar :value="calculateProgress(numberFormat(totalCount()),totalMarketSum())">
                      <span>
                        <strong>{{calculateProgress(numberFormat(totalCount()),totalMarketSum())}}%</strong></span
                      >
                    </b-progress-bar>
                  </b-progress>
                </div>
              </li>
            </ul>


          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="c-card p-7">
          <div class="patient-income-table tableFixHead">
            <table class="timeline-tbl">
              <thead>
              <tr>
                <th>Timeline</th>
                <th>{{ filter.report_type=='collection'?'Markets':'Products' }}</th>
                <th>Total</th>
               
              </tr>
              </thead>
  
              <tbody v-for="(data, index) in table_data" :key="index" :class="data.expand?'expanded-row':''">
              <tr v-if="!data.expand">
                <td @click="handleRowClicked(index, data.date)" style="cursor:pointer;">
                  {{ data.isToday && filter.period == 'monthly'?'Today':dateFormat(data.date) }}
                </td>
                <td>All {{ filter.report_type=='collection'?'Markets':'Products' }}</td>
                <td>{{  totalAreaWiseTableData(data.area_wise_sale)  }}</td>
              </tr>
  
              <tr v-if="data.expand && data.uniqueCategories && data.uniqueCategories.length" v-for="(service, sIndex) in data.uniqueCategories" :key="sIndex">
                <td :rowspan="data.uniqueCategories.length" v-if="sIndex == 0" style="vertical-align: top !important; cursor:pointer;"  @click="handleRowClicked(index, data.date)">
                  <strong>{{ data.isToday && filter.period == 'monthly'?'Today':dateFormat(data.date) }}</strong>
                </td>
                <td>{{ filter.report_type == 'collection'?service.sales_market:service.category_name }}</td>
                <td>
                  {{ areaCategoryWiseTotal(data.services, service.category_id, 'A') + 
                  areaCategoryWiseTotal(data.services, service.category_id, 'B') +
                  areaCategoryWiseTotal(data.services, service.category_id, 'C') +
                  areaCategoryWiseTotal(data.services, service.category_id, 'Others') + 
                  areaCategoryWiseTotal(data.services, service.category_id, 'D') }}
                </td>
              </tr>

              <tr v-if="data.expand && data.uniqueCategories && data.uniqueCategories.length" class="expanded-total-row">
                <td>
                  <ul>
                    <li>
                      <strong>Total</strong>
                    </li>
                    <li>Yesterday</li>
                  </ul>
                </td>
                <td></td>
                
                <td>
                  <ul>
                    <li>
                      <strong>{{ totalAreaWiseTableData(data.area_wise_sale) }}</strong>
                    </li>
                    <li>
                      <span :class="calculatePercentage(totalAreaWiseTableData(data.area_wise_sale), totalAreaWiseTableData(data.prev_day)) <= 0?'down-text':'up-text'"
                  ><i class="fas" :class="calculatePercentage(totalAreaWiseTableData(data.area_wise_sale), totalAreaWiseTableData(data.prev_day)) <= 0?'fa-chevron-down':'fa-chevron-up'"></i> {{ calculatePercentage(totalAreaWiseTableData(data.area_wise_sale), totalAreaWiseTableData(data.prev_day)) }}%</span
                  >
                    </li>
                  </ul>
                </td>
                
              </tr>
  
              <tr v-if="data.expand && data.uniqueCategories && !data.uniqueCategories.length">
              <td>
                <strong>{{ data.isToday && filter.period == 'monthly'?'Today':dateFormat(data.date) }}</strong>
              </td>
              <td colspan="2">
                <strong>Data not available</strong>
              </td>
            </tr>

              </tbody>
             
            </table>
          </div>
  
          <div class="summary-wrapper" v-if="table_data.length">
            <table class="summary-tbl">
              <thead>
              <tr>
                <th style="width: 36%;">
                  {{ (filter.period == 'monthly')?filter.month+' '+filter.year: 'Q'+filter.quarter+' '+filter.year }}
                </th>
                <th style="width: 10%; text-align: right;">{{ 
                  saleCalculate()
                }}</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td style="width: 36%;">Achievement</td>
               
                <td style="width: 10%; text-align: right;">
                    <span>{{ calculateProgress(numberFormat(totalCount()),totalMarketSum()) }}%</span>
                </td>
                
                
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import axios from "axios";
  import moment from "moment";
  
  export default {
    name: "CorporateReport",
    data() {
      let all_months = [
      {
          text: "Q1",
          value: "1"
        },
        {
          text: "Jan",
          value: "January",
        },
        {
          text: "Feb",
          value: "February",
        },
        {
          text: "Mar",
          value: "March",
        },
        {
          text: "Q2",
          value: "2"
        },
        {
          text: "Apr",
          value: "April",
        },
        {
          text: "May",
          value: "May",
        },
        {
          text: "Jun",
          value: "June",
        },
        {
          text: "Q3",
          value: "3"
        },
        {
          text: "Jul",
          value: "July",
        },
        {
          text: "Aug",
          value: "August",
        },
        {
          text: "Sep",
          value: "September",
        },
        {
          text: "Q4",
          value: "4"
        },
        {
          text: "Oct",
          value: "October",
        },
        {
          text: "Nov",
          value: "November",
        },
        {
          text: "Dec",
          value: "December",
        },
      ];
      return {
        loading: false,
        value: 88,
        max: 100,
        months: all_months,
        filter: {
          period: 'monthly',
          month: '',
          year: '',
          quarter: '',
          type: 'pcs',
          area: '',
          report_type: 'sales',
        },
        start_year: 2015,
        month: all_months[new Date().getMonth()].value,
        year: new Date().getFullYear(),
        years: [],
        product_wise_sale: [],
        market_wise_sale: [],
        area_wise_sale: [],
        territory_wise_sale: [],
        last_month: [],
        showMonthFilter: false,
        showAreaFilter: false,
        showTypeFilter: false,
        showSalesFilter: false,
        table_data: [],
        unique_categories: [],
        corporate_target: {},
      };
    },
    created() {
      this.filter.month = moment().format('MMM'),
      this.filter.year = moment().format('YYYY'),
      this.getReports();
      localStorage.setItem('tabIndex', 16)
      this.$root.$emit('call-active-menu', 16);
      this.setYears(8);
    },
    methods: {
      setSalesOrCollection(report_type){
        if(report_type == 'sales') {
           this.filter.report_type = 'collection';
           this.filter.type = 'tk';
        }else {
           this.filter.report_type = 'sales';
        }
        this.getReports();
      },
      pcsOrTk(type) {
        if(this.filter.report_type == 'collection') {
          this.filter.type ='tk';
        } else {
          if(type == 'pcs') {
            this.filter.type = 'tk';
          } else {
            this.filter.type = 'pcs';
          }
        }
        this.getReports();
      },
      capitalizeFirstLetter(string) {
          return string.charAt(0).toUpperCase() + string.slice(1);
      },
      getYearLabel(year) {
        return year?String(year).substring(2,4):''
      },
      selectPeriod(type, value) {
        this.filter.period = type;
        if(type == 'monthly') {
          this.filter.quarter = '';
          this.filter.month = value;
        } else {
          this.filter.month = '';
          this.filter.quarter = value;
        }
      },
      numberFormat(num) {
        return num?(Math.round(num * 100) / 100).toFixed(0):0;
      },
      calculateProgress(achieved, target) {
        if(target > 0) {
          return Math.floor((achieved*100)/target);
        }
        return 0;
      },
      checkTargetExists(product) {
        return this.targets.some(item => item.product_type == product);
      },
      
      
      calculatePercentage(currentMonth, prevMonth) {
        prevMonth = prevMonth?prevMonth:0;
        let diff = currentMonth - prevMonth;
        if(diff != 0 && prevMonth != 0) {
          return Math.round((diff / prevMonth * 100) * 100) / 100
          // return Math.floor(diff / prevMonth * 100);
        } else if (diff != 0 && prevMonth == 0) {
          return Math.round(diff * 100) / 100
          // return Math.floor(diff);
        } else {
          return 0;
        }
      },
      incomeCalculate(type) {
        let total = 0;
        this.table_data.forEach((item) => {
          total += item[type]
        });
        return total;
      },
      totalCount() {
        if(this.market_wise_sale) {

          // let product_wise_sale = 0;
          // this.product_wise_sale.forEach((item) => {
          //   product_wise_sale += parseInt(item.total_sale)
          // });
          
          let market_wise_sale = 0;
          this.market_wise_sale.forEach((item) => {
            market_wise_sale += parseInt(item.total_sale)
          });
          
        return market_wise_sale;
      }

        
      },
      serviceTotal(type) {
        let total = 0;
        this.services.forEach((item) => {
          if(type == 'total_income' && item.product_type == 'Dental') {
            total += Math.round(item[type]*15/100)
          } else {
            total += item[type]
          }
        });
        return total;
      },
      targetTotal(type) {
        let total = 0;
        this.targets.forEach((item) => {
          total += item[type]
        });
        return total;
      },
      dateFormat(date) {
        if(this.filter.period == 'monthly') {
          return date?moment(date).format('D MMM'):'';
        } else {
          return date?moment(date).format('MMM YYYY'):'';
        }
      },
      selectFilter(type) {
        if (type == "Month") {
          this.showAreaFilter = false;
          this.showTypeFilter = false;
          this.showSalesFilter = false;
          this.showMonthFilter = !this.showMonthFilter;
        } else if (type == "Area") {
          this.showMonthFilter = false;
          this.showTypeFilter = false;
          this.showSalesFilter = false;
          this.showAreaFilter = !this.showAreaFilter;
        } else if ( type == 'Type') {
          this.showMonthFilter = false;
          this.showAreaFilter = false;
          this.showSalesFilter = false;
          this.showTypeFilter = !this.showTypeFilter;
        } else if ( type == 'Sales') {
          this.showMonthFilter = false;
          this.showAreaFilter = false;
          this.showTypeFilter = false;
          this.showSalesFilter = !this.showSalesFilter;
        }
      },
      getReports() {
        this.loading = true;
        this.area_wise_sale = [];
        this.territory_wise_sale = [];
        axios
        // const url = `${process.env.VUE_APP_HALDA_VALLAY_API}/api/reports/sales?api_token=${process.env.VUE_APP_HALDA_VALLAY_API_TOKEN}&period=monthly&type=tk&month=Aug&year=2020&quarter=3`
        const url = `${process.env.VUE_APP_HALDA_VALLAY_API}/api/reports/sales?api_token=${localStorage.getItem('api_token')}${this.filter.month?'&month='+this.filter.month:''}${this.filter.type?'&type='+this.filter.type:''} ${this.filter.area?'&area='+this.filter.area:''}&period=${this.filter.period}&year=${this.filter.year}${this.filter.quarter?'&quarter='+this.filter.quarter:''}&report_type=${this.filter.report_type?this.filter.report_type:'sales'}&sales_channel=corporate`;
            axios.get(url)
            .then((res) => {
              this.product_wise_sale = res.data.product_wise_sale;
              this.market_wise_sale = res.data.market_wise_sale;
              if(res.data.targets) {
                for (const target of res.data.targets) {
                    // Iterate through the 'channels' array under each 'target'
                    for (const channel of target.channels) {
                        // Check if the 'type' key's value is "Retail"
                        if (channel.type === "Corporate") {
                            // Found a Retail object, you can access and process its content here
                            this.corporate_target = channel;
                        }
                    }
                }
              }
              if(this.filter.area) {
                this.territory_wise_sale = res.data.territory_wise_sale;
              } else {
                this.area_wise_sale = res.data.area_wise_sale;
              }

              this.last_month = res.data.last_month;
              this.table_data = res.data.data.reverse();
              this.table_data = this.table_data.map((item => {
                item.prev_day = {};
                item.services = [];
                item.uniqueCategories = [];
                if(moment(item.date).isSame(moment(), 'day')) {
                  // item.expand = false; //TODO
                  item.isToday = true;
                } else {
                  // item.expand = false;
                  item.isToday = false;
                }
                return item;
              }))
  
              // this.services = res.data.service_counts;
              // this.lastMonthData = res.data.last_month;
              // this.targets = res.data.target_counts;
              this.loading = false;
  
              // let servArr = [];
              // if(this.filter.patientType == 'Zero Clubfoot') {
              //   servArr = ['Ponseti (Plaster)', 'Ponseti (Surgery)', 'Ponseti (Tenotomy)', 'registration_fee', 'brace', 'appointment'];  
              // } else {
              //   servArr = ['therapy_appointment', 'therapy_session'];  
              // }
              
              // servArr.forEach(serv => {
              //   if(!this.services.some(item => item.product_type == serv)) {
              //     this.services.push({ product_type: serv, patient_count: 0, total_income: 0 })
              //   }
              // });
              
            });
      },
      totalMarketSum() {
        let sum = 0;
        if(this.corporate_target.markets) {
          this.corporate_target.markets.forEach(item => {
            if(this.filter.type == 'pcs') {
              sum += parseFloat(item.target_pcs);
            }else {
              sum += parseFloat(item.target_amount);
            }
          })
        }
        return sum;
      },
      getTarget(category) {
      
        let sum = 0;''
        if(this.corporate_target.products) {
          this.corporate_target.products.forEach(target => {
          // target.categories.forEach(item => {
            if(target.category_id == category.category_id) {
              if(this.filter.type == 'pcs') {
                sum += parseFloat(target.target_pcs);
              }else {
                sum += parseFloat(target.target_amount);
              }
            }
          // })
          })
        }


      return sum;
       
     },
     getTargetMarket(market) {
     
      let sum = 0;''
      // this.targets.forEach(target => {
        if(this.corporate_target.markets) {
        
          this.corporate_target.markets.forEach(item => {
            if(item.sales_market == market.sales_market) {
              if(this.filter.type == 'pcs') {
                sum += parseFloat(item.target_pcs);
              }else {
                sum += parseFloat(item.target_amount);
              }
            }
          })
          // })
        }

     return sum;
      
    },
      handleRowClicked(index, date) {
        this.loading = true;
        const url = `${process.env.VUE_APP_HALDA_VALLAY_API}/api/reports/sales/detail?api_token=${localStorage.getItem('api_token')}&date=${date}${this.filter.month?'&month='+this.filter.month:''}${this.filter.type?'&type='+this.filter.type:''} ${this.filter.area?'&area='+this.filter.area:''}&period=${this.filter.period}&year=${this.filter.year}${this.filter.quarter?'&quarter='+this.filter.quarter:''}&report_type=${this.filter.report_type?this.filter.report_type:'sales'}&sales_channel=corporate`;

        axios
            .get(url)
            .then((res) => {
              this.loading = false;
              // var arrDataSorted = [...new Set(res.data.data.filter(i => i.category_name))];

              // this.unique_categories = arrDataSorted
              // console.log('res dada', res.data.data);
              this.table_data = this.table_data.map(((item, key) => {
                            if(index == key) {
                              item.expand = !item.expand;
                              item.services = res.data.data;
                              item.uniqueCategories = this.uniqueCategories(res.data.data);
                              item.prev_day = res.data.prev_day;
                            } else {
                              item.expand = false;
                            }
                            return item;
                          }));

                       
            });
  
  
      },

      uniqueCategories(data) {
        return data.reduce((acc, elem)=>{
          let isPresent = acc.findIndex(k=>k.category_id==elem.category_id);
          if(isPresent==-1){
            acc.push(elem);
          } else {
            if(new Date(acc[isPresent].date) < new Date(elem.date)) acc[isPresent] = elem;
          }
          return acc;
        },[]);
      },
      // findAreaData(area) {
      //   if(this.last_month) {
      //     const areaObj = this.last_month.find(item => item.area == area);
      //     if(areaObj) {
      //         return areaObj.total_sale;
      //     } else {
      //       return 0;
      //     }
      //   }
        
      // },

    saleCalculate(area) {
      let total = 0;
      this.table_data.forEach((item) => {
        if(item.area_wise_sale) {
          if(area && area == 'Others') {
            item.area_wise_sale.forEach((val) => {
              if(val.area != 'A' && val.area != 'B' && val.area != 'C' && val.area != 'D') {
                total += val.total_sale?parseFloat(val.total_sale):0
              }
            });
          } else if(area) {
            const areaObj = item.area_wise_sale.find(val => val.area == area);
            total += areaObj?parseFloat(areaObj.total_sale):0
          } else {
            item.area_wise_sale.forEach((val) => {
              total += val.total_sale?parseFloat(val.total_sale):0
            });
          }
        }
      });
      return total;
    },
    territorySaleCalculate(territory) {
      let total = 0;
      this.table_data.forEach((item) => {
        if(item.territory_wise_sale) {
          if(territory && territory == 'Others') {
            item.territory_wise_sale.forEach((val) => {
              if(val.territory != 1 && val.territory != 2 && val.territory != 3) {
                total += val.total_sale?parseFloat(val.total_sale):0
              }
            });
          } else if(territory) {
            const territoryObj = item.territory_wise_sale.find(val => val.territory == territory);
            total += territoryObj?parseFloat(territoryObj.total_sale):0
          } else {
            item.territory_wise_sale.forEach((val) => {
              total += val.total_sale?parseFloat(val.total_sale):0
            });
          }
        }
      });
      return total;
    },

      findAreaWiseTableData(area,dataObj) {
        if(!dataObj) {
          return 0;
        }
        const areaObj = dataObj.find(item => item.area == area);
        if(area == 'A' && areaObj) {
            return areaObj.total_sale;
        } else if (area == 'B' && areaObj) {
          return areaObj.total_sale;
        } else if (area == 'C' && areaObj) {
          return areaObj.total_sale;
        } else if (area == 'D' && areaObj) {
          return areaObj.total_sale;
        } else {
          return 0;
        }
      },

      findTerritoryWiseTableData(territory,dataObj) {
        if(!dataObj) {
          return 0;
        }
        const territoryObj = dataObj.find(item => item.territory == territory);
        if(territory == 1 && territoryObj) {
            return territoryObj.total_sale;
        } else if (territory == 2 && territoryObj) {
          return territoryObj.total_sale;
        } else if (territory == 3 && territoryObj) {
          return territoryObj.total_sale;
        } else {
          return 0;
        }
      },

      findAreaWiseOthersTotal(dataArr) {
        if(!dataArr) {
          return 0;
        }
        let sum = 0;
        dataArr.forEach( item => {
          if(item.area != 'A' && item.area != 'B' && item.area != 'C' && item.area != 'D') {
            sum += parseFloat(item.total_sale);
          }
        })
        return sum;
      },

      findTerritoryWiseOthersTotal(dataArr) {
        if(!dataArr) {
          return 0;
        }
        let sum = 0;
        dataArr.forEach( item => {
          if(item.territory != 1 && item.territory != 2 && item.territory != 3) {
            sum += parseFloat(item.total_sale);
          }
        })
        return sum;
      },

      totalAreaWiseTableData(data) {
        if(!data) {
          return 0;
        }
        let sum = 0;
        data.forEach( num => {
          sum += parseFloat(num.total_sale);
        })
        return sum;
      },

      totalTerritoryWiseTableData(data) {
        if(!data) {
          return 0;
        }
        let sum = 0;
        data.forEach( num => {
          sum += parseFloat(num.total_sale);
        })
        return sum;
      },

      areaCategoryWiseTotal(data, categoryId, area) {
        let sum = 0;
        data.forEach( item => {
          if(area == 'Others') {
            if(item.category_id == categoryId && item.area != 'A' && item.area != 'B' && item.area != 'C' && item.area != 'D') {
              sum += parseFloat(item.total_sale);
            }
          } else {
            if(item.category_id == categoryId && item.area == area) {
              sum += parseFloat(item.total_sale);
            }
          }
        })
        return sum;
      },

      territoryCategoryWiseTotal(data, categoryId, territory) {
        let sum = 0;
        data.forEach( item => {
          if(territory == 'Others') {
            if(item.category_id == categoryId && item.territory != 1 && item.territory != 2 && item.territory != 3) {
              sum += parseFloat(item.total_sale);
            }
          } else {
            if(item.category_id == categoryId && item.territory == territory) {
              sum += parseFloat(item.total_sale);
            }
          }
        })
        return sum;
      },

      setYears(num) {
        for (let i = 0; i <= num; i++) {
          this.years.push(this.start_year + i);
        }
      },
      getPercentage(total,item) {
        if(item != 0) {
        const parcentage = Math.round((100 * item) / total);
        return parcentage;
        }
        return 0;
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  .c-card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 15px; /* 5px rounded corners */
    min-height: 200px;
    background-color: #fff;
    width: 100%;
    float: left;
  
    .filter-list {
      width: 100%;
      float: left;
      list-style-type: none;
      margin-top: 20px;
      margin-bottom: 20px;
  
      li {
        width: 33.333333333%;
        float: left;
        padding: 0px 4px 0;
        border-right: 2px solid #002170;
        text-align: center;
  
        &:last-of-type {
          border-right: none;
          padding: 0;
        }
  
        select {
          border: none;
          outline: none;
          scroll-behavior: smooth;
          -webkit-appearance: listbox !important;
  
          color: #002170;
          font-size: 15px;
          font-weight: 600;
          font-family: Arial;
          padding: 0 12px 0 8px;
          height: 22px;
        }
      }
    }
  
    .target-content {
      width: 100%;
      float: left;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      transition: 0.3s;
      border-radius: 15px; /* 5px rounded corners */
      min-height: 200px;
      background-color: #004E3F;
      padding: 25px;
  
      h2 {
        color: #FFD600;
        font-family: Arial;
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 15px;
      }
  
      ul {
        list-style-type: none;
        margin-bottom: 20px;
        width: 100%;
        float: left;
  
        li {
          width: 100%;
          float: left;
  
         
          .l-label {
            width: 45%;
            float: left;
          }
  
          .r-label {
            text-align: right;
            color: #fff;
            font-weight: 600;
            font-size: 18px;
            font-family: Arial;
  
            .achieved {
              color: #f9a91a;
            }
          }
  
          label {
            color: #fff;
            font-size: 18px;
            font-family: Arial;
            font-weight: 600;
          }
  
          &.total {
            label {
              font-size: 20px;
            } 
            .r-label {
              font-size: 20px;
                .achieved {
                  color: #44B3E6;
                }
              }
              .progress-bar {
                background-color: #44B3E6 !important;
              }
          }
        }
      }
    }
  
    .timeline-tbl {
      font-family: Arial, Helvetica, sans-serif;
      border-collapse: collapse;
      width: 100%;
      float: left;
      .expanded-row {
        box-shadow: 0 0 0 1px #AAA;
        border-radius: 20px;
      }
  
      tr {
        th {
          color: #002147;
          border-bottom: 1px solid #a0a0a1;
          padding-bottom: 10px;
          text-align: center;
          font-size: 16px;
        }
      }
  
      tbody {
        tr {
          &.expanded-total-row {
            border-top: 1px solid #AAA;
           td {
              ul {
                margin: 6px 0;
                list-style-type: none;
                li {
                  line-height: 1.6;
                  &:last-of-type {
                    font-size: 13px;
                    span {
                      font-size: 13px;
                      i {
                        font-size: 13px;
                      }
                    }
                  }
                }
              }
           }
          }
          td {
            text-align: center;
            font-size: 16px;
            line-height: 2.5;
            color: #002170;
          }
  
          &:first-of-type {
            td {
              padding-top: 10px;
            }
          }
        }
      }
    }
  
    .summary-wrapper {
      width: 100%;
      float: left;
      background-color: #004E3F;
      padding: 8px 30px;
      border-radius: 20px;
      margin-top: 30px;
  
      .summary-tbl {
        font-family: Arial, Helvetica, sans-serif;
        border-collapse: collapse;
        width: 100%;
        float: left;
  
        tr {
          th {
            color: #fff;
            font-size: 17px;
            padding-bottom: 5px;
          }
  
          td {
            font-size: 15px;
            color: #fff;
            font-weight: 600;
          }
        }
      }
    }
  } 
  .month-selection-list {
  width: 100%;
  float: left;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin-top: 20px;

  li {
    width: 25%;
    float: left;
    text-align: center;
    margin-bottom: 20px;

    a {
      background-color: #EBEBEB;
      padding: 7px 25px;
      border-radius: 20px;
      color: #000;
      font-family: Arial;

      &.active {
        background-color: #F9A81A;
        font-weight: 600;
      }
    }

    &:nth-child(1), &:nth-child(5), &:nth-child(9), &:nth-child(13) {
      a {
        background-color: #AAAAAA;
        color: #fff;
        &.active {
          background-color: #F9A81A;
          font-weight: 600;
        }
      }
    }
  }
}

.year-selection-list {
  width: 100%;
 
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin-top: 20px;
  display: flex;
  justify-content: center;

  li {
    text-align: center;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    a {
      background-color: #d9d4d4;
      padding: 7px 25px;
      border-radius: 20px;
      color: #000;
      font-family: Arial;
      margin: 5px;

      &.active {
        background-color: #F9A81A;
        font-weight: 600;
      }
    }

    &:nth-child(1), &:nth-child(5), &:nth-child(9), &:nth-child(13) {
      a {
        background-color: #AAAAAA;
        color: #fff;
        &.active {
          background-color: #F9A81A;
          font-weight: 600;
        }
      }
    }
  }
}
  .brd-15 {
    border-radius: 15px;
  }
  
  .ftb-15 {
    font-size: 15px;
    font-weight: 600;
  }
  
  .up-text {
    color: #00cd32;
    font-size: 15px;
    font-weight: 600;
  
    i {
      color: #00cd32;
      font-size: 13px;
    }
  }
  
  .down-text {
    color: red;
    font-size: 15px;
    font-weight: 600;
  
    i {
      color: red;
      font-size: 13px;
    }
  }
  
  .coll-btn {
    color: #000;
    font-size: 14px;
    font-weight: 600;
    font-family: Arial;
    padding: 4px 10px 4px 10px;
    height: 30px;
    background-color: #EBEBEB;
    border: none;
    border-radius: 20px;
    min-width: 50px;
  
    &:active {
      background-color: transparent !important;
    }
  
    &:focus {
      background-color: transparent !important;
    }
  }
  
  
  .center-selection-list {
    width: 100%;
    float: left;
    list-style-type: none;
    font-family: Arial;
    font-size: 16px;
    margin-top: 20px;
  
    li {
      width: 25%;
      float: left;
      text-align: center;
      margin-bottom: 20px;
  
      a {
        background-color: #EBEBEB;
        padding: 7px 20px;
        border-radius: 20px;
        color: #000;
        font-family: Arial;
  
        &.active {
          background-color: #F9A81A;
          font-weight: 600;
        }
      }
    }
  }

  .progress_bar_bullet {
    font-size: 12px;
    font-weight: 500;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 10px;
    p {
      margin-left: 10px;
      color: #fff;
      margin-bottom: 6px;
    }
    span {
      height: 11px;
      width: 11px;
      display: inline-block;
      margin-right: 5px;
    }
    .area_a {
      background: #F9A81A;
    }
    .area_b {
      background: #FFD600;
    }
    .area_c {
      background: #66F755;
    }
    .area_d {
      background: #44B3E6;
    }
    .area_others {
      background: #FFFFFF;
    }
  }
  
  .patient-selection-list {
    width: 100%;
    float: left;
    list-style-type: none;
    font-family: Arial;
    font-size: 16px;
    margin-top: 20px;
  
    li {
      width: 50%;
      float: left;
      text-align: center;
      margin-bottom: 20px;
  
      a {
        background-color: #EBEBEB;
        padding: 8px 30px;
        border-radius: 20px;
        color: #000;
        font-family: Arial;
  
        &.active {
          background-color: #F9A81A;
          font-weight: 600;
        }
      }
    }
  }
  .area-selection-list {
    width: 100%;
    float: left;
    list-style-type: none;
    font-family: Arial;
    font-size: 16px;
    margin-top: 20px;
  
    li {
      width: 25%;
      float: left;
      text-align: center;
      margin-bottom: 20px;
  
      a {
        background-color: #EBEBEB;
        padding: 8px 30px;
        border-radius: 20px;
        color: #000;
        font-family: Arial;
  
        &.active {
          background-color: #F9A81A;
          font-weight: 600;
        }
      }
    }
  }

  .report-selection-list {
    width: 100%;
    float: left;
    list-style-type: none;
    font-family: Arial;
    font-size: 16px;
    margin-top: 20px;
  
    li {
      width: 33.3333333%;
      float: left;
      text-align: center;
      margin-bottom: 20px;
  
      a {
        background-color: #EBEBEB;
        padding: 8px 30px;
        border-radius: 20px;
        color: #000;
        font-family: Arial;
  
        &.active {
          background-color: #F9A81A;
          font-weight: 600;
        }
      }
    }
  }
  
.area_a_bg {
  background-color: #F9A81A !important;
}
.area_b_bg {
  background-color: #FFD600 !important;
}
.area_c_bg {
  background-color: #66F755 !important;
}
.area_d_bg {
  background-color: #44B3E6 !important;
}
.default_progressbar_bg {
  background-color: #FFFFFF !important;
}
  .tableFixHead {
    overflow: auto;
    height: 500px;
  }
  
  .tableFixHead thead th {
    position: sticky;
    top: 0;
    background-color: #fff;
  }
  
  .patient-income-table {
    height: 475px;
    overflow: scroll;
    // padding: 2px;
  }
  .area-wise-sale-title {
    color: #FFD600 !important;
    margin-top: 20px;
  }
  .collection-active-bg {
    background-color: #F9A81B;
    color: #FFF;
  }
  .tk-active-bg {
    background-color: #FFD600;
  }
  </style>
  